import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { InstagramMedia, getInstagramMedia } from '../../pages/api/instagram/media';
import { cardHover } from '../../styles/index.css';
import { Routes } from '../../utils/const';
import { Box, Center, Stack, Text } from '../Design';
import { Spinner } from '../Design/Primitives/components/spinner';
import { Picture } from '../Design/Starters/Picture';
import LinkButton from '../LinkButton';

const InstagramMediaItem = ({ permalink, media_url, caption, media_type }: InstagramMedia) => (
  <Box
    as="a"
    rel="noopener noreferrer"
    target="_blank"
    href={permalink ?? Routes.instagram}
    flex="1"
    borderRadius="base"
    overflow="hidden"
    boxShadow="base"
    maxWidth="full"
    style={{ aspectRatio: '1 / 1' }}
    className={cardHover}
    aria-label={`Opens new tab for Instagram Post`}>
    <Center as="span" style={{ aspectRatio: '1 / 1' }} overflow="hidden">
      {media_type !== 'VIDEO' ? (
        <Box
          as={Picture}
          image={{ default: { src: media_url, alt: `Instagram Image ${caption?.replace(/(\r\n|\n|\r)/gm, '')}` } }}
          width="auto"
          height="full"
        />
      ) : (
        <Center backgroundColor="gray" width="full" height="full">
          <ReactPlayer url={media_url} width="auto" height="100%" muted loop playing />
        </Center>
      )}
    </Center>
  </Box>
);

const InstagramSection = () => {
  const [instagramMedia, setInstagramMedia] = useState<InstagramMedia[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    if (!mounted) return;

    if (typeof window !== 'undefined') {
      (async () => {
        const instagramMediaRes = (await getInstagramMedia()) ?? null;
        if (instagramMediaRes) {
          setInstagramMedia(instagramMediaRes);
        }

        setIsLoading(false);
      })();
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (isLoading) {
    return (
      <Center>
        <Stack>
          <Spinner size="lg" color="primary" margin="auto" />
          <Text>Loading...</Text>
        </Stack>
      </Center>
    );
  }

  if (!instagramMedia || !Array.isArray(instagramMedia)) {
    return (
      <Text width="full" textAlign="center">
        We are currently experiencing issues with Instagram Integration. To view our profile, please click{' '}
        <LinkButton
          href={Routes.instagram}
          aria-label="Opens new tab for Instagram Profile"
          buttonProps={{
            variant: 'link',
            height: '44px',
            rel: 'noopener noreferrer',
            target: '_blank',
            textTransform: 'capitalize'
          }}>
          here
        </LinkButton>
      </Text>
    );
  }

  return (
    <>
      <Box
        display={{ mobile: 'grid', tablet: 'none', desktop: 'none' }}
        gap={['12px', '20px', '24px']}
        gridTemplateColumns="2x">
        {instagramMedia?.slice(0, 4)?.map((instagramMedia) => (
          <InstagramMediaItem {...instagramMedia} key={`instagram-mobile-` + instagramMedia?.id} />
        ))}
      </Box>
      <Box display={{ mobile: 'none', tablet: 'grid' }} gap={['12px', '20px', '24px']} gridTemplateColumns="5x">
        {instagramMedia?.slice(0, 5)?.map((instagramMedia) => (
          <InstagramMediaItem {...instagramMedia} key={`instagram-` + instagramMedia?.id} />
        ))}
      </Box>
    </>
  );
};

export default InstagramSection;
