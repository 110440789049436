import { NextApiRequest, NextApiResponse } from 'next';
import { updateContentfulInstagramTokenEntry } from '../contentful/update-Instagram-access-token';
import { BASE_URL, apiError } from '../utils';

const instagramRefreshToken = async (req: NextApiRequest, res: NextApiResponse) => {
  try {
    const { instagramUserToken } = req?.query;
    const response = await fetch(
      `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${instagramUserToken}`
    );

    const resp = response ? await response.json() : null;

    res.status(200);
    res.json(resp);
  } catch (error) {
    apiError({ errorInText: 'INSTAGRAM REFRESH TOKEN', error, res });
  }
};

export const getInstagramRefreshToken = async (instagramUserToken: string | undefined) => {
  try {
    const response = await fetch(`${BASE_URL}/api/instagram/refresh-token?instagramUserToken=${instagramUserToken}`);

    const resJson = await response?.json();
    const token = resJson?.access_token;

    if (token) {
      await updateContentfulInstagramTokenEntry(token);
    }

    return token;
  } catch (e: any) {
    console.error('getInstagramRefreshToken failed', e);
    if (e) return e;
  }
};

export default instagramRefreshToken;
