import { NextRequest } from 'next/server';
import { getOffsetDate } from '../../../utils';
import { apiError, BASE_URL } from '../utils';
import { contentfulConsistentHeaders, ContentfulEntryIds } from '../utils/contentful';

export const config = {
  runtime: 'edge'
};

const updateInstagramAccessToken = async (req: NextRequest) => {
  const { searchParams } = new URL(req.url);

  const accessToken = searchParams.get('accessToken');

  try {
    const expirationDate = getOffsetDate(50)?.offsetDate;

    const instagramAccessTokenEntryEndPoint = `https://api.contentful.com/spaces/xrnhoqzvjqps/environments/master/entries/${ContentfulEntryIds.instagramToken}`;

    // Get Entry
    const getInstagramTokenEntry = await fetch(instagramAccessTokenEntryEndPoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.CONTENTFUL_PERSONAL_ACCESS_TOKEN}`,
        'Content-Type': 'application/vnd.contentful.management.v1+json'
      }
    });
    const updateInstagramAccessTokenEntry = await getInstagramTokenEntry.json();

    // Update Entry
    const updateResponse = await fetch(instagramAccessTokenEntryEndPoint, {
      method: 'PUT',
      headers: {
        ...contentfulConsistentHeaders,
        'Content-Type': 'application/vnd.contentful.management.v1+json',
        'X-Contentful-Version': updateInstagramAccessTokenEntry?.sys?.version
      },
      body: JSON.stringify({
        ...updateInstagramAccessTokenEntry?.metadata,
        fields: {
          ...updateInstagramAccessTokenEntry?.fields,
          accessToken: {
            'en-US': accessToken
          },
          expirationDate: {
            'en-US': expirationDate
          }
        }
      })
    });
    const updatedInstagramAccessTokenEntry = await await updateResponse.json();

    // Publish Entry
    const publishedResponse = await fetch(instagramAccessTokenEntryEndPoint + '/published', {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${process.env.CONTENTFUL_PERSONAL_ACCESS_TOKEN}`,
        'X-Contentful-Version': updatedInstagramAccessTokenEntry?.sys?.version
      }
    });

    const resJSON = await publishedResponse.json();

    return new Response(JSON.stringify(resJSON), {
      status: 200,
      headers: {
        'content-type': 'application/json'
      }
    });
  } catch (error) {
    apiError({ errorInText: 'UPDATE INSTAGRAM ACCESS TOKEN', error, isEdge: true });
  }
};

export const updateContentfulInstagramTokenEntry = async (accessToken: string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/contentful/update-Instagram-access-token?accessToken=${accessToken}`);

    return await response?.json();
  } catch (e: any) {
    console.error('updateContentfulInstagramTokenEntry failed', e);
    if (e) return e;
  }
};

export default updateInstagramAccessToken;
